<!--公用底部操作栏-->
<template>

  <div class="global-footer-editor">
    <a-button :loading="loading" size="large" type="primary" @click="() => (this.$emit('success'))">保 存</a-button>
    <a-button size="large" @click="() => (this.$emit('cancel'))">取 消</a-button>
  </div>

</template>

<script>
export default {
  name: 'GlobalFooter',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
