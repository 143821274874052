<!--门店详情-->
<template>
  <div class="furnish-detail">

    <div class="global-view-card role-flex">

      <a-form-model ref="roleForm" class="role-form" :inline="true" :model="formInfo" :rules="rules">

        <a-form-model-item label="门店名称" prop="name">

          <a-input v-model="formInfo.name" size="large" placeholder="请输入门店名称" />

        </a-form-model-item>

        <a-form-model-item label="门店地址" prop="address">

          <GCitySelect v-model="formInfo.address" placeholder="请选择或搜索地址" />

        </a-form-model-item>

        <a-form-model-item label="详细地址" prop="addressDetail">

          <TextArea v-model="formInfo.addressDetail" :auto-size="{ minRows: 4, maxRows: 10 }" type="textarea" size="large" placeholder="请输入门店详细地址" />

        </a-form-model-item>

        <a-form-model-item label="门店店长" class="select-input" prop="contactPersonId">

          <Select
            v-model="formInfo.contactPersonId"
            size="large"
            show-search
            :filter-option="false"
            placeholder="输入人员名称搜索"
            @search="value => { searchLoad = true; handleSearch(value) }"
          >
            <Spin v-if="searchLoad" slot="notFoundContent" size="small" />
            <SelectOption v-for="item in staffOption" :key="item.userId" :value="item.userId">{{ item.nickName }}</SelectOption>
          </Select>

        </a-form-model-item>

        <a-form-model-item label="联系电话" prop="contactPhone">

          <a-input v-model="formInfo.contactPhone" size="large" placeholder="请输入联系电话" :max-length="11" />

        </a-form-model-item>

        <a-form-model-item label="营业时间" prop="businessHours">

          <a-input v-model="formInfo.businessHours" size="large" placeholder="请输入营业时间" />

        </a-form-model-item>

      </a-form-model>

    </div>

    <GlobalFooter :loading="saveLoad" @success="saveInfo" @cancel="backView" />

  </div>
</template>

<script>
import { shopUpdate, accountPage } from '@/api/public'
import GlobalFooter from '@/components/GlobalFooter'
import GCitySelect from '@/components/GCitySelect'
import { Input, Select, Spin } from 'ant-design-vue'
export default {
  name: 'RoleDetail',
  components: {
    GlobalFooter,
    TextArea: Input.TextArea,
    GCitySelect,
    Select,
    SelectOption: Select.Option,
    Spin
  },
  props: {
    appInfo: {
      type: Object,
      default: () => {}
    },
    editorId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      saveLoad: false,
      rules: {
        name: [{ required: true, message: '请输入门店名称' }],
        address: [{ required: true, message: '请输入门店地址' }],
        contactPhone: [{ required: true, message: '请输入联系电话' }],
        contactPersonId: [{ required: true, message: '请选择店长' }],
        businessHours: [{ required: true, message: '请输入营业时间' }],
        addressDetail: [{ required: true, message: '请输入门店详细地址' }]
      },
      formInfo: {
        name: '',
        contactPhone: '',
        businessHours: '',
        contactPersonId: undefined,
        address: { code: '', label: '' }
      },
      searchLoad: false,
      authData: [],
      staffOption: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    // 搜索
    handleSearch(search) {
      accountPage({ key: search }).then(res => {
        if (res.data.list) {
          res.data.list.forEach(v => {
            v.nickName = v.user.nickName
          })
        }
        this.staffOption = res.data.list
        this.searchLoad = false
      }).catch(() => { this.searchLoad = false })
    },
    async init() {
      accountPage().then(res => {
        res.data.list.forEach(v => {
          v.nickName = v.user.nickName
        })
        this.staffOption = res.data.list
      })
      // 编辑模式
      if (this.editorId) {
        this.formInfo = {
          ...this.appInfo,
          address: {
            label: this.appInfo.address.split(','),
            code: this.appInfo.addressCode
          }
        }
      }
    },
    saveInfo() {
      this.$refs.roleForm.validate(valid => {
        if (valid) {
          const requestData = {
            ...this.formInfo,
            address: this.formInfo.address.label.join(','),
            addressCode: this.formInfo.address.code
          }
          this.editorAPI(requestData)
        }
      })
    },
    // 编辑
    editorAPI(requestData) {
      this.saveLoad = true
      shopUpdate({
        ...requestData
      }).then(() => {
        this.$message.success('编辑成功')
        this.backView()
      }).catch(() => (this.saveLoad = false))
    },
    // 返回
    backView() {
      this.saveLoad = false
      this.$emit('change', {
        name: 'furnishCard'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.furnish-detail{
  width: 100%;
  height: 100%;
  padding: 12px;
  overflow-y: auto;
  position: relative;
  .role-flex{
    display: flex;
    padding: 0;
    height: calc(100% - 64px);
    overflow-y: auto;
  }
  .role-form{
    width: 600px;
    height: max-content;
    padding: 32px;
    ::v-deep {
      .ant-form-item-control-wrapper{
        width: calc(100% - 100px);
        .ant-form-item-control{
          width: 100%;
        }
      }
      .ant-row{
        display: flex;
        .ant-select-selection-selected-value{
          font-size: 14px;
        }
      }
    }

  }
  .auth-model{
    width: calc(100% - 600px);
    height: 100%;
    overflow-y: auto;
    border-left: 1px #f2f2f2 solid;
    padding: 20px 0 20px 20px;
    .global-title{
      font-size: 14px;
    }
    .ant-checkbox-group{
      width: 100%;
    }
    .auth-item{
      width: 90%;
      border: 1px #e8e8e8 solid;
      margin-top: 20px;
      border-radius: 4px;
      .auth-menu-name{
        height: 48px;
        line-height: 48px;
        display: block;
        font-size: 14px;
        color: var(--titleColor);
        border-bottom: 1px #fafafa solid;
        padding: 0 16px;
        background: var(--viewBg);
      }
      .auth-item-menu{
        margin-bottom: 10px;
      }
      .auth-menu{
        padding: 16px 16px 0;
        &:last-child{
          .auth-item-border{
            border-bottom: 0;
          }
        }
      }
      .item-menu{
        display: inline-block;
        padding: 16px;
      }
      .auth-item-border{
        border-bottom: 1px #f2f2f2 solid;
        padding-bottom: 16px;
      }
    }
  }
}
</style>
